import React, { useContext, useState } from "react";
import { AuthContext } from "../../../auth";
import axios from "axios";
import get from "lodash/get";
import { toast } from "react-toastify";

const UserConnectionCredentials = () => {
  const { user } = useContext(AuthContext);

  const [progress, setProgress] = useState(false);

  const passwordChangeRequest = async (e) => {
    e.preventDefault();
    setProgress(true);
    await axios.post("/api/sendmail", {
      userEmail: get(user, "data.email", ""),
      subject: `[corsnet.lk] Password Change Request`,
      fields: [
        {
          key: "First Name",
          value: get(user, "data.name", ""),
        },
        {
          key: "Email",
          value: get(user, "data.email", ""),
        },
        {
          key: "User ID",
          value: get(user, "data.id", "N/A"),
        },
        {
          key: "Message",
          value: "Please change my password",
        },
      ],
    });

    toast.success(
      "We got your request. One of our agents will get back to you soon."
    );
    setProgress(false);
  };

  return (
    <div>
      <div className="mb-4">
        <h5>Existing Credentials: </h5>
        <p className={"h6"}>
          Preferred Username:{" "}
          {get(user, "data.connection_username", "Not Available") ||
            "Not Available"}
        </p>
        <p className={"h6"}>
          Preferred Password:{" "}
          {get(user, "data.connection_password", "Not Available") ||
            "Not Available"}
        </p>

        <button
          className="btn btn-secondary me-3"
          onClick={passwordChangeRequest}
        >
          Request Password Change
        </button>
        {progress && (
          <div
            className="spinner-border text-muted spinner-border-sm"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserConnectionCredentials;
