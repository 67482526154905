import React from "react";
import Layout from "../../layout";
import UserProfile from "./IndexComponents/_UserProfile";
import UserPassword from "./IndexComponents/_UserPassword";
import UserConnectionCredentials from "./IndexComponents/_UserConnectionCredentials";

const ProfilePage = () => {
  return (
    <Layout
      title={"User Account"}
      description={"Update user profile and change password"}
      privatePage
    >
      <section className={`new-connection-form py-8 py-lg-16 container`}>
        <section className="form">
          <UserConnectionCredentials />
          <hr />
          <UserProfile />
          <hr />
          <UserPassword />
        </section>
      </section>
    </Layout>
  );
};

export default ProfilePage;
