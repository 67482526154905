import React, { useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

const UserPassword = () => {
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "password" : ""
  );
  const [newPassword, setNewPassword] = useState(
    process.env.NODE_ENV === "development" ? "password" : ""
  );
  const [newPassword2, setNewPassword2] = useState(
    process.env.NODE_ENV === "development" ? "password" : ""
  );

  const [message, setMessage] = useState(null);
  const [progress, setProgress] = useState(null);

  const disabled =
    !password ||
    newPassword.length < 5 ||
    newPassword !== newPassword2 ||
    progress;

  const changePassword = (e) => {
    e.preventDefault();
    setProgress(true);
    setMessage(null);

    const data = {
      password,
      newPassword,
      newPassword2,
    };

    try {
      api("post", "user/reset-password", data).then((res) => {
        setProgress(false);
        if (res.data.status === "error") {
          toast.error(res.data.message);
        } else if (res.data.status === "success") {
          toast.success("Password changed successfully.");
          setPassword("");
          setNewPassword("");
          setNewPassword2("");
        }
      });
    } catch (error) {
      setProgress(false);
      toast.error(
        "Error occurred. If this error persists, please contact system administrator."
      );
    }
  };

  return (
    <form action="" method="post" onSubmit={(e) => e.preventDefault()}>
      <div className="mb-4">
        <label htmlFor="password">Current Password</label>
        <input
          type="password"
          name="password"
          id="password"
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="new_password">New Password</label>
        <input
          type="password"
          name="new_password"
          id="new_password"
          className="form-control"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="confirm_password">Confirm New Password</label>
        <input
          type="password"
          name="confirm_password"
          id="confirm_password"
          className="form-control"
          value={newPassword2}
          onChange={(e) => setNewPassword2(e.target.value)}
        />
      </div>

      <button
        type="submit"
        disabled={disabled || progress}
        className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
        onClick={changePassword}
      >
        Change Password
      </button>
    </form>
  );
};

export default UserPassword;
