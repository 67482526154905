import React, { useContext, useState } from "react";
import api from "../../../services/api";
import { AuthContext } from "../../../auth";
import { toast } from "react-toastify";

const UserProfile = () => {
  const { user } = useContext(AuthContext);

  const [message, setMessage] = useState(null);
  const [progress, setProgress] = useState(null);

  const [name, setName] = useState(user?.data?.name || "");
  const [company, setCompany] = useState(user?.data?.company || "");
  const [email, setEmail] = useState(user?.data?.email || "");
  const [phone, setPhone] = useState(user?.data?.phone || "");
  const [address1, setAddress1] = useState(user?.data?.address_line_1 || "");
  const [address2, setAddress2] = useState(user?.data?.address_line_2 || "");
  const [city, setCity] = useState(user?.data?.address_line_3 || "");

  const disabled = !name || !email || !phone || !address1 || !city || progress;

  const handleUpdate = (e) => {
    e.preventDefault();
    setProgress(true);
    setMessage(null);

    const data = {
      name,
      email,
      phone,
      company,
      address1,
      address2,
      address3: city,
    };

    try {
      api("post", "user/update", data).then((res) => {
        setProgress(false);
        if (res.data.status === "error") {
          toast.error(res.data.message);
        } else if (
          typeof window !== "undefined" &&
          res.data.status === "success"
        ) {
          toast.success("Successfully updated.");
        }
      });
    } catch (error) {
      setProgress(false);
      toast.error(
        "Error occurred. If this error persists, please contact system administrator."
      );
    }
  };

  return (
    <form action="" method="post" onSubmit={(e) => e.preventDefault()}>
      <div className="mb-4">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          id="name"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="company">Company</label>
        <input
          type="text"
          name="company"
          id="company"
          className="form-control"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="email">Email</label>
        <input
          type="text"
          name="email"
          id="email"
          disabled
          readOnly
          className="form-control"
          value={email}
          onChange={() => null}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="phone">Phone number</label>
        <input
          type="text"
          name="phone"
          id="phone"
          className="form-control"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="address">Address</label>
        <input
          type="text"
          name="address"
          id="address"
          className="form-control"
          value={address1}
          onChange={(e) => setAddress1(e.target.value)}
        />
        <input
          type="text"
          name="address2"
          id="address2"
          className="form-control my-1"
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
        />
        <input
          type="text"
          name="city"
          id="city"
          className="form-control"
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </div>

      <button
        type="submit"
        value="View Summary And Submit"
        className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
        onClick={handleUpdate}
        disabled={disabled}
      >
        Update Profile
      </button>
    </form>
  );
};

export default UserProfile;
